import React from "react";
import { graphql, PageProps } from "gatsby";
import SEO from "gatsby-plugin-wpgraphql-seo";

import {
  BrandCampaignHeroType,
  CustomerPersonaType,
  HowYouGrowSectionType,
  OtherProductsType,
  PartneredWithLogosPType,
  WhatWeDeliverType,
  WhySimplepracticeSection,
  YoastSEOType,
} from "@types";

import { deIndexSeo } from "@utils/deIndexSeo";
import { RegisterCtaButtonPlayVideo } from "@components/BuilderWrapperComponents/CtaButtonPlayVideo";
import { RegisterEmailOnlyForm } from "@components/BuilderWrapperComponents/EmailOnlyFormBC";
import { RegisterPrimaryBtn } from "@components/BuilderWrapperComponents/PrimaryBtnBC";
import { RegisterPrimaryBtnWithArrow } from "@components/BuilderWrapperComponents/PrimaryBtnWithArrowBC";
import { RegisterReviews } from "@components/BuilderWrapperComponents/ReviewsBC";
import B2BHomeContainer from "@containers/B2BContainer/B2BHomeContainer";
import CustomerPersonaBannerContainer from "@containers/CustomerPersonaBannerContainer/CustomerPersonaBannerContainer";
import DefaultHomePageLayout from "@layouts/DefaultHomePageLayout";
import HomeHeroContainer from "@containers/HomeHeroContainer";
import HowYouGrowContainer from "@containers/HowYouGrowContainer";
import OtherProductsContainer from "@containers/OtherProductsContainer";
import WhatWeDeliver from "@containers/WhatWeDeliverContainer";
import WhySimplePracticeSection from "@components/WhySPSection";
import styles from "./styles.module.scss";

type HomePageType = {
  wpPage: {
    seo: YoastSEOType;
    title: string;
    template: {
      brandCampaignHero: BrandCampaignHeroType;
      partneredWithLogosP: PartneredWithLogosPType;
      whySimplepracticeSection: WhySimplepracticeSection;
      products: OtherProductsType;
      howYouGrowSection: HowYouGrowSectionType;
      whatWeDeliverSection: WhatWeDeliverType;
      customerPersonas: CustomerPersonaType;
      resourcesBlogRelationship: Queries.WpHomeTemplate_Resourcesblogrelationship;
    };
  };
  location: PageProps["location"];
  allBuilderModels: any;
};

const HomePage: React.FC<PageProps<HomePageType>> = ({ data, location }) => {
  const { wpPage } = data;

  const {
    template: {
      whySimplepracticeSection,
      products,
      howYouGrowSection,
      whatWeDeliverSection,
      customerPersonas,
      resourcesBlogRelationship,
    },
  } = wpPage;
  const heroContent = data?.allBuilderModels?.oneHomeHeroSection?.content;

  const whatWeDeliverProps = {
    ...whatWeDeliverSection,
    resourcesBlogRelationship,
  };

  return (
    <DefaultHomePageLayout pathname={location.pathname}>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}

      <HomeHeroContainer heroContent={heroContent} />

      <B2BHomeContainer />

      <WhySimplePracticeSection
        whySpContent={{ ...whySimplepracticeSection }}
        headerClassName={styles.simpleContainerHeaderSection}
      />
      <CustomerPersonaBannerContainer props={{ ...customerPersonas }} />
      <HowYouGrowContainer
        sectionData={howYouGrowSection}
        location={location}
      />
      <OtherProductsContainer content={products} />
      <WhatWeDeliver props={whatWeDeliverProps} />
    </DefaultHomePageLayout>
  );
};

RegisterPrimaryBtnWithArrow();
RegisterPrimaryBtn();
RegisterEmailOnlyForm();
RegisterReviews();
RegisterCtaButtonPlayVideo();

export const query = graphql`
  query HomeTemplateQuery($id: String!, $path: String!) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      template {
        ... on WpHomeTemplate {
          ...FragmentBrandCampaignHeroHomeP
          ...FragmentCustomerPersonas
          ...FragmentHowYouGrow
          ...FragmentOtherProducts
          ...FragmentPartneredWithLogosP
          ...FragmentWhatWeDeliver
          ...FragmentWhySimplePractice
          ...FragmentResourcesBlog
        }
      }
    }
    allBuilderModels {
      oneHomeHeroSection(
        target: { urlPath: $path }
        options: { cachebust: false }
      ) {
        content
      }
    }
  }
`;

export default HomePage;
